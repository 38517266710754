import { combineReducers } from 'redux';
import accordionReducer from '@cotyorg/ccx-molecules/Accordion/modules';
import cardoListReducer from '@cotyorg/ccx-organism-cardo-list/modules';
import carouselReducer from '@cotyorg/ccx-organism-carousel/modules';
import cookieReducer from '@cotyorg/ccx-organism-cookie/modules';
import dragSwipeReducer from '@cotyorg/ccx-utils/js/components/DragSwipe/modules';
import headerReducer from '@cotyorg/ccx-organism-header/modules';
import navigationReducer from '@cotyorg/ccx-organism-navigation/modules';
import countrySelectorReducer from '@cotyorg/ccx-organism-country-selector/modules';
import pictureReducer from '@cotyorg/ccx-atoms/Picture/modules';
import productSwatchReducer from '@cotyorg/ccx-organism-product-swatch/modules';
import { content as contentReducer } from '@cotyorg/ccx-server/modules';
import breakpointReducer from '@cotyorg/ccx-utils/js/components/BreakpointProvider/modules';
import sliderReducer from '@cotyorg/ccx-molecules/Slider/modules';
import videoReducer from '@cotyorg/ccx-organism-video/modules';
import searchReducer from '@cotyorg/ccx-organism-search/modules';
import filterReducer from '@cotyorg/ccx-organism-filter/modules';
import modalReducer from '@cotyorg/ccx-molecules/Modal/modules';
import newsletterReducer from '@cotyorg/ccx-molecules/Newsletter/modules';
import productInfoReducer from '../organisms/ProductInfo/modules';
import productRetailersReducer from '../organisms/ProductRetailers/modules';

const rootReducer = combineReducers({
  accordions: accordionReducer,
  cardoList: cardoListReducer,
  carousels: carouselReducer,
  content: contentReducer,
  cookie: cookieReducer,
  breakpoints: breakpointReducer,
  countrySelector: countrySelectorReducer,
  dragSwipe: dragSwipeReducer,
  header: headerReducer,
  filter: filterReducer,
  modals: modalReducer,
  navigation: navigationReducer,
  pictures: pictureReducer,
  productInfo: productInfoReducer,
  productRetailers: productRetailersReducer,
  productSwatch: productSwatchReducer,
  sliders: sliderReducer,
  newsletter: newsletterReducer,
  status: (state = {}) => state,
  search: searchReducer,
  videos: videoReducer, // TODO: [1] this should be video?
  location: (state = {}) => state,
  presentationLocale: (state = {}) => state,
});

export default rootReducer;
