import { TOGGLE_ALL_SHADES } from './types';

const toggleAllShades = (state) => ({
  ...state,
  isOpen: !state.isOpen,
});

const reducer = {
  [TOGGLE_ALL_SHADES]: toggleAllShades,
};

const initialState = {
  isOpen: false,
};

export default (state = initialState, action) =>
  action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
