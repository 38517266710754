/* eslint-disable global-require */
import universal from 'react-universal-component';
import { setupRouter } from '@cotyorg/ccx-server/router';
import { safeImport } from '@cotyorg/ccx-server/utils';

const ErrorPage = universal(import('./pages/Error'));
const NotFound = universal(import('./pages/NotFound'));
const Home = universal(import('./pages/Home'));
const PrideCampaign = universal(import('./pages/PrideCampaign'));
const Product = universal(import('./pages/Product'));
const Trends = universal(import('./pages/Trends'));
const TrendsArticle = universal(import('./pages/TrendsArticle'));
const AllTrends = universal(import('./pages/AllTrends'));
const Category = universal(import('./pages/Category'));
const SearchResults = universal(import('./pages/SearchResults'));
const HalloweenCampaign = universal(import('./pages/HalloweenCampaign'));
const HowTo = universal(import('./pages/HowTo'));
const AllHowTo = universal(import('./pages/AllHowTo'));
const HowToArticle = universal(import('./pages/HowToArticle'));
const New = universal(import('./pages/New'));
const SallyStory = universal(import('./pages/SallyStory'));
const Faq = universal(import('./pages/Faq'));
const About = universal(import('./pages/About'));
const Join = universal(import('./pages/Join'));
const WhereToBuy = universal(import('./pages/WhereToBuy'));
const Impressum = universal(import('./pages/Impressum'));
const Vto = universal(import('./pages/Vto'));
const VtoAdvanced = universal(import('./pages/VtoAdvanced'));
const VtoPrivacyPolicy = universal(import('./pages/VtoPrivacyPolisy'));

const routerConfig = {
  helperComponents: {
    ErrorComponent: ErrorPage,
  },

  defaultPages: {
    homeOverrides: {
      component: Home,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/home').default
      ),
    },
    notFoundOverrides: {
      component: NotFound,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/not-found').default
      ),
    },
    categoryOverrides: {
      component: Category,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/category').default
      ),
    },
    productOverrides: {
      component: Product,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/product').default
      ),
    },
  },

  additionalRoutes: [
    // This path is only to be used by ReactRouter Config, accessing this page directly is an edge case
    {
      path: '/:locale/error',
      exact: true,
      id: 'error',
      title: 'Error',
      component: ErrorPage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/error').default
      ),
    },
    {
      path: '/:locale/trends',
      exact: true,
      id: 'trends',
      title: 'Trends',
      component: Trends,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/trends').default
      ),
    },
    {
      path: '/:locale/trends/all-trends',
      exact: true,
      id: 'all-trends-page',
      title: 'All Trends Page',
      component: AllTrends,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/all-trends').default
      ),
    },
    {
      path: '/:locale/trends/:trend',
      exact: true,
      id: 'trends-article-page',
      title: 'Trends Article Page',
      component: TrendsArticle,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/trends-article').default
      ),
    },
    {
      path: '/:locale/how-to',
      exact: true,
      id: 'how-to-page',
      title: 'How to Page',
      component: HowTo,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/how-to').default
      ),
    },
    {
      path: '/:locale/how-to/all-:howto',
      exact: true,
      id: 'how-to-all-page',
      title: 'How to all page',
      component: AllHowTo,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/all-how-to').default
      ),
    },
    {
      path: '/:locale/how-to/:howto',
      exact: true,
      id: 'how-to-article-page',
      title: 'How to article page',
      component: HowToArticle,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/how-to-article').default
      ),
    },
    {
      path: '/:locale/search',
      exact: false,
      id: 'search-results-page',
      title: 'Search Results Page',
      component: SearchResults,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/search-results').default
      ),
    },
    {
      path: '/:locale/whats-new',
      exact: true,
      id: 'new',
      title: 'New',
      component: New,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/new').default
      ),
    },
    {
      path: '/:locale/about-us/sally-story',
      exact: false,
      id: 'sally-story-page',
      title: 'Sally Story Page',
      component: SallyStory,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/sally-story').default
      ),
    },
    {
      path: '/:locale/about-us/pride',
      exact: false,
      id: 'pride-campaign-page',
      title: 'Pride Campaign Page',
      component: PrideCampaign,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/pride-campaign').default
      ),
    },
    {
      path: '/:locale/about-us/halloween',
      exact: false,
      id: 'halloween-campaign-page',
      title: 'Halloween Campaign Page',
      component: HalloweenCampaign,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/halloween-campaign').default
      ),
    },
    {
      path: '/:locale/about-us/frequently-asked-questions',
      exact: true,
      id: 'frequently-asked-questions',
      title: 'Frequently Asked Questions Page',
      component: Faq,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/faq').default
      ),
    },
    {
      path: '/:locale/about-us/where-to-buy',
      exact: true,
      id: 'where-to-buy-page',
      title: 'Where to buy Page',
      component: WhereToBuy,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/where-to-buy').default
      ),
    },
    {
      path: '/:locale/about-us',
      exact: false,
      id: 'about-page',
      title: 'About Page',
      component: About,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/about').default
      ),
    },
    {
      path: '/:locale/join-us',
      exact: false,
      id: 'join-page',
      title: 'Join Page',
      component: Join,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/join').default
      ),
    },
    {
      path: '/:locale/impressum',
      exact: true,
      id: 'impressum-page',
      title: 'Impressum Page',
      component: Impressum,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/impressum').default
      ),
    },
    {
      path: '/:locale/virtual-try-on-qr',
      exact: true,
      id: 'vto-page',
      title: 'Vto Page',
      component: Vto,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/vto').default
      ),
    },
    {
      path: '/:locale/virtual-try-on-qr-copy',
      exact: true,
      id: 'vto-page-copy',
      title: 'Vto Advanced Page',
      component: VtoAdvanced,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/vto-advanced').default
      ),
    },
    {
      path: '/:locale/vto-privacy-policy',
      exact: true,
      id: 'vto-privacy-policy-page',
      title: 'Vto Privacy Policy Page',
      component: VtoPrivacyPolicy,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/vto-privacy-policy').default
      ),
    },
  ],

  routesToRemove: [
    // 'example', // NB: there is no example route, so this won't remove anything - but this is the mechanism
  ],
};

export default setupRouter(routerConfig);
