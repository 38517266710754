import { updateContent } from '@cotyorg/ccx-server/modules/content/actions';
import { TOGGLE_MORE_PRODUCT_RETAILERS } from './types';

export const toggleMoreRetailers = () => ({
  type: TOGGLE_MORE_PRODUCT_RETAILERS,
});

export const fetchRetailers = ({ skus, locale }) => (dispatch) =>
  fetch(`/${locale}/api/product-retailers/${skus}`)
    .then((res) => res.json())
    .then((retailersJson) => {
      dispatch(
        updateContent({
          key: 'retailers',
          data: retailersJson,
          shouldMerge: false,
        })
      );
    })
    .catch(() => null);
